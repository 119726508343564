/* eslint-env node */

// This little module saves us importing every single png file manually.

const context = require.context('./', false, /\.png$/);
const iconList = {};
context.keys().forEach(key => {
  const name = key
    .split('./')
    .pop() // remove the ./ from the start.
    // remove the extension (4 chars,but remember we have 2 less at the start)
    .substring(0, key.length - 6);
  iconList[name] = context(key);
});

module.exports = iconList;
