import React from 'react';

import { css, border, txt, combine } from '../../style/utils';

import icons from './icons';
import stackedLogoImage from './logo-stacked.png';

import styles from './RadialFeatureList.module.scss';

const classes = {
  center: css({
    alignItems: 'center',
    textAlign: 'center',
  }),
};

const features = [
  {
    key: 'plugplay',
    icon: icons.plugplay,
    classes: [classes.center],
    alt: 'Desktop computer screen showing information',
    title: 'Your messages on any screen, any size, any time',
    text: 'Simply plug the control box into the screen you want to use',
  },
  {
    key: 'price',
    icon: icons.piggy,
    classes: [],
    alt: 'Piggy bank',
    title: 'One price, one provider, one solution',
    text: 'Hardware, software and support all included in one price',
  },
  {
    key: 'scale',
    icon: icons.graph,
    classes: [],
    alt: 'Graph showing an upward trend',
    title: 'Grow your digital signage with your business',
    text: 'Easy to scale up as your needs change',
  },
  {
    key: 'speed',
    icon: icons.dial,
    classes: [classes.center],
    alt: 'Dial indicating high speed',
    title: 'Bespoke branded content in moments',
    text:
      'Brand your signage beautifully in moments - ' +
      'no designers or programmers needed',
  },
  {
    key: 'spectacular',
    icon: icons.spectacular,
    classes: [classes.center],
    alt: 'Desktop computer screen showing formatted content',
    title: 'Simple to set up, spectacular to look at',
    text:
      'Choose from a variety of pre-loaded templates ' +
      'for truly professional signage',
  },
  {
    key: 'schedule',
    icon: icons.chat,
    classes: [],
    alt: 'Chat bubbles indicating a dialogue',
    title: 'Timed to perfection',
    text:
      'Control when your messages are displayed, how long for ' +
      'and how regularly they’re shown ',
  },
  {
    key: 'secure',
    icon: icons.secure,
    classes: [],
    alt: 'Shield with a checkmark icon within it',
    title: 'Safe as houses',
    text:
      'Have confidence in the security of your data - ' +
      'we use enterprise-grade security to protect your data ' +
      'as if it were our own',
  },
];

const RadialFeatureList = () => (
  <div className={styles.wrapper}>
    <div className={styles.outerRing}>
      <div className={styles.logoContainer}>
        <img
          src={stackedLogoImage}
          className={styles.logo}
          alt="Pixelnebula logo"
        />
      </div>
    </div>
    {features.map((feature, i) => (
      <div
        key={i}
        className={combine(
          styles.featureWrapper,
          styles[`featureWrapper__${feature.key}`]
        )}
      >
        <div
          className={combine(
            styles.feature,
            styles[`feature__${feature.key}`],
            ...feature.classes
          )}
        >
          <div className={combine(styles.featureLine, border.blue)} />
          <img
            src={feature.icon}
            className={styles.featureIcon}
            alt={feature.alt}
          />
          <li className={styles.featureText}>
            <h6 className={styles.featureTitle}>{feature.title}</h6>
            <p className={combine(txt.blueGrey, 'feature')}>{feature.text}</p>
          </li>
        </div>
      </div>
    ))}
  </div>
);

export default RadialFeatureList;
