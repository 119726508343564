import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { bg, combine, txt, flex } from '../style/utils';
import { getImage } from '../utils/queryUtils';

import HeroSpace from '../components/HeroSpace/HeroSpace';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import RadialFeatureList from '../components/RadialFeatureList/RadialFeatureList';
import ActionButton from '../components/ActionButton/ActionButton';
import styles from './index.module.scss';

const IndexPage = ({ data }) => (
  <div>
    <HeroSpace
      title="Enterprise Digital Signage"
      subtitle="Simple to use. Beautiful to look at."
      button={{
        text: 'Request a demo to see it in action',
        link: '/contact?type=demo',
        alt: 'Flare digital signage product showcase',
      }}
      image={{
        fluid: data.heroImage.fluid,
        alt:
          'Laptop showing content management system in front of a digital sign showing a branded slide',
      }}
      extraChildren={
        <Img className={styles.clientList} {...data.clientsImage} />
      }
    />

    <ContentWrapper variant="dark">
      <div className={styles.headerContent}>
        <div className={styles.flex1}>
          <h3>Introducing Flare</h3>
          <h4>Your content on any screen, anywhere</h4>
        </div>
        <div className={styles.flex1}>
          <p>
            Crystal clear and reactive communication can grow your business,
            motivate your staff, and help things run seamlessly. So, when you
            value your brand, you need reactive digital signage that looks great
            and makes communicating easy.
          </p>
          <p>That&apos;s exactly why we designed Flare.</p>
          <p>
            With plenty of eye-catching templates that easily integrate your
            logo and brand colours, Flare lets you share messages and
            information quickly on any screen, anytime, in real time.
          </p>
        </div>
      </div>
      <Img
        className={styles.introducingFlareImg}
        {...getImage(data.pageImages, 'introducing.png')}
      />
    </ContentWrapper>

    <ContentWrapper backgroundClasses={bg.lightBlue}>
      <div className={styles.whyFlare}>
        <h5>Why power your digital signage with Flare?</h5>
        <div className="featureList">
          <RadialFeatureList />
        </div>
      </div>
    </ContentWrapper>

    <ContentWrapper variant="dark">
      <div className={combine(txt.center, flex.col, flex.aic)}>
        <h3>Start a free trial of Flare today</h3>
        <h4>No credit card required</h4>
        <ActionButton
          className={styles.seeItInActionButton}
          variant="secondary"
          modifiers={['hero']}
          link="/contact?type=trial"
        >
          Request a Trial
        </ActionButton>
      </div>
    </ContentWrapper>
  </div>
);

export default IndexPage;

export const pageQuery = graphql`
  query indexAssets {
    pageImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/introducing[.]png/" } } }
    ) {
      edges {
        node {
          fluid(maxWidth: 1330, quality: 85) {
            name: originalName
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    heroImage: imageSharp(
      fluid: { originalName: { regex: "/index-hero[.]png/" } }
    ) {
      fluid(maxWidth: 1330, quality: 85, traceSVG: { color: "#ffffff" }) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
    clientsImage: imageSharp(
      fluid: { originalName: { regex: "/clients[.]png/" } }
    ) {
      fluid(maxWidth: 1330, quality: 85) {
        name: originalName
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
